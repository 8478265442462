<template>
    <div class="pb-20 pt-20">
        <div class="container mx-auto grid grid-cols-1 p-5 sm:w-full md:w-4/12">
            
            <div v-if="campaignCategory.length > 0">
                <!-- <h3> <i class="fa fa-list-ul"></i> KATEGORI <strong>{{ category.name.toUpperCase() }}</strong></h3> -->
                
                <div class="mt-5 grid grid-cols-4 gap-4" v-for="campaign in filteredCategoryCampaigns" :key="campaign.id">
                    
                    <div class="col-span-4">
                        <CampaignLargeItem 
                            :image="campaign.image" 
                            :title="campaign.title" 
                            :slug="campaign.slug" 
                            :sumDonation="campaign.sum_donation"
                            :targetDonation="campaign.target_donation"
                            :maxDate="campaign.max_date" 
                            :typeCampaign="campaign.tipe_campaign" /> 
                    </div>
                </div>
            </div>
            <div v-else>

                <div class="mb-3 bg-red-500 text-white p-4 rounded-md">
                    Data Campaign Berdasarkan Kategori <strong>{{ category.name }}</strong> Belum Tersedia!
                </div>

            </div>

        </div>
    </div>
</template>

<script>

    //hook vue
    import { onMounted, computed } from 'vue'

    //hook vuex
    import { useStore } from 'vuex'

    //hook vue router
    import { useRoute } from 'vue-router'

    import CampaignLargeItem from '@/components/CampaignLargeItem.vue'

    export default {

        components: {
            CampaignLargeItem  // <-- register component FacebooLoader dari Vue Content Loader
        },

        setup() {

            //store vuex
            const store = useStore()

            //const route
            const route = useRoute()

            //onMounted akan menjalankan action "getDetailCategory" di module "category"
            onMounted(() => {
                document.title = `Lihat Kategoti ${route.params.slug}`;
                store.dispatch('category/getDetailCategory', route.params.slug)
            })

            //digunakan untuk get data state "category" di module "category" 
            const category = computed(() => {
                return store.state.category.category
            })

            //digunakan untuk get data campaign di satate "campaignCategory" di module "category" 
            const campaignCategory = computed(() => {
                return store.state.category.campaignCategory
            })

            const today = computed(() => {
                return new Date().toISOString().split('T')[0];
            });

            const filteredCategoryCampaigns = computed(() => {
                return campaignCategory.value.filter(campaign =>
                    campaign.max_date >= today.value && campaign.sum_donation.total !== 0
                );
            });
            return {
                category,           // <-- state category
                campaignCategory,    // <-- state campaignCategory
                filteredCategoryCampaigns
            }
        }

    }
</script>

<style>

</style>